import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import SimpleBackdrop from "../../components/Backdrop";
import { errorMessage, successMessage } from "../../helpers/Message";
import instance from "../../services/apiServices/Api";
import Storage from "../../services/local_storage/localStorage";
import * as Yup from "yup";
import CustomizedHook from "../../components/customHooks/CustomizedHook";
import { AppTexts } from "../../constants/AppTexts";
import ReleaseFormModal from "../../components/ReleaseFormModal";
import AppCustomButton, { buttonVariants, buttonSizes } from "../../components/AppCustomButton";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Paper,
  Typography,
  RadioGroup,
  Radio,
  Container,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { AppColors } from "../../constants/AppColors";

function GalleryEditDownload() {
  const { id } = useParams();
  const { state } = useLocation();
  const token = Storage.getToken("token");
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [releaseList, setReleaseList] = useState([]);
  const [releaseInfo, setReleaseInfo] = useState([]);
  const navigate = useNavigate();
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [isFictional, setIsFictional] = useState(false);
  const [generativeTags] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = (id) => {
    setLoading(true);
    instance
      .post("/contributor/downloads", { download_id: id, type: state.type }, header)
      .then((res) => {
        setLoading(false);
        setReleaseList(res.data.releases);
        for (let item in formik.initialValues) {
          if ([item] in res.data.data) {
            formik.setFieldValue(item, res.data.data[item] === null ? "" : res.data.data[item]);
          }
          if (["is_recognized"].includes(item)) {
            formik.setFieldValue("is_recognized", res.data.data.is_recognized ? "1" : "0");
          }
          if (["is_aigenerated"].includes(item)) {
            formik.setFieldValue("is_aigenerated", res.data.data.is_aigenerated ? "1" : "0");
            setIsAIGenerated(res.data.data.is_aigenerated === 1);
          }
        }

        formik.setFieldValue(
          "release_id",
          res.data.data.release.length <= 0 ? [] : res.data.data.release.map((item) => item.release_id)
        );
        setSelected(res.data.data.tags.map((item) => item.name));
        setReleaseInfo(res.data.data.release.map((item) => item.release_info));
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      download_id: [id],
      title: "",
      description: "",
      media_type: "creative",
      is_recognized: "0",
      submit_for_approval: state.submit_for_approval,
      release_id: [],
      tags: [],
      is_aigenerated: "0",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is Required")
        .max(255, "Title must be less than 255 characters"),
      description: Yup.string()
        .required("Description is Required")
        .max(250, "Description must be less than 250 characters"),
      media_type: Yup.string().required("Media type is Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      if (values.is_aigenerated === "1" && !isFictional) {
        setLoading(false);
        return errorMessage(AppTexts.Error, AppTexts.CheckPeopleAreFictional);
      }

      if (selected.length >= 6) {
        if (values.is_recognized === "1") {
          if (values?.release_id?.length <= 0 || !values.release_id) {
            setLoading(false);
            return errorMessage("Error", "Please Select Release Form");
          }
        }

        let data = {
          ...values,
          tags: [...selected, ...generativeTags],
          is_recognized: parseInt(values.is_recognized),
          is_aigenerated: parseInt(values.is_aigenerated),
        };

        instance
          .post("contributor/downloads/update", data, header)
          .then((res) => {
            setLoading(false);
            successMessage("Success", "Updated Successfully").then(() =>
              navigate("/contributor-dashboard/gallery")
            );
            resetForm();
            setSelected([]);
          })
          .catch((error) => {
            setLoading(false);
            errorMessage("Error", error.response?.data?.message || "Something went wrong");
          });
      } else {
        setLoading(false);
        errorMessage("Error", "Required minimum 6 keywords");
      }
    },
  });

  const handleAIGeneratedChange = (event) => {
    const isGenerated = event.target.checked;
    setIsAIGenerated(isGenerated);
    formik.setFieldValue("is_aigenerated", isGenerated ? "1" : "0");
    handleGenerativeTags(isGenerated ? "1" : "0");
  };

  const handleGenerativeTags = (isGenerated) => {
    if (isGenerated === "1") {
      const newTags = [...new Set([...selected, "AI", "Generative Image", "Generative"])];
      setSelected(newTags);
    } else {
      const filteredTags = selected.filter(
        (tag) => !["AI", "Generative Image", "Generative"].includes(tag)
      );
      setSelected(filteredTags);
    }
  };

  const handleTagChange = (newTags) => {
    const processedTags = newTags
      .flatMap(tag => tag.split(',').map(subTag => subTag.trim()))
      .filter(tag => tag !== "");
    setSelected(processedTags);
  };

  const multiSearch = (newInputValue) => {
    const release_ids = newInputValue.map((re) => re.id);
    formik.setFieldValue("release_id", release_ids);
  };

  return (
    <Container maxWidth="md">
      {loading && <SimpleBackdrop />}
      <Typography 
        variant="h4" 
        component="h2" 
        sx={{ 
          mb: 4,
          color: AppColors.TextColor,
          fontWeight: 600
        }}
      >
        Edit Download Details
      </Typography>
      
      <Paper 
        elevation={0} 
        sx={{ 
          p: 4, 
          borderRadius: 3,
          border: `1px solid ${AppColors.BorderColor}`,
          backgroundColor: '#fff'
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: AppColors.TextColor 
                }}
              >
                Title
              </Typography>
              <OutlinedInput
                id="title-input"
                fullWidth
                placeholder="Enter title"
                {...formik.getFieldProps("title")}
                error={formik.touched.title && Boolean(formik.errors.title)}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '10px',
                    borderColor: AppColors.BorderColor,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: AppColors.PrimaryColor,
                  },
                }}
              />
              {formik.touched.title && formik.errors.title && (
                <FormHelperText error>{formik.errors.title}</FormHelperText>
              )}
            </Box>

            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: AppColors.TextColor 
                }}
              >
                Keywords
              </Typography>
              <Box sx={{ 
                border: `1px solid ${AppColors.BorderColor}`,
                borderRadius: '10px',
                p: 1,
                '&:hover': {
                  borderColor: AppColors.PrimaryColor,
                },
              }}>
                <TagsInput
                  value={[...selected, ...generativeTags]}
                  onChange={handleTagChange}
                  name="tags"
                  separators={["Enter", ","]}
                  placeHolder="Enter Keywords (minimum 6)"
                />
              </Box>
            </Box>

            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: AppColors.TextColor 
                }}
              >
                Description
              </Typography>
              <OutlinedInput
                id="description-input"
                fullWidth
                multiline
                rows={4}
                placeholder="Enter description"
                {...formik.getFieldProps("description")}
                error={formik.touched.description && Boolean(formik.errors.description)}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '10px',
                    borderColor: AppColors.BorderColor,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: AppColors.PrimaryColor,
                  },
                }}
              />
              {formik.touched.description && formik.errors.description && (
                <FormHelperText error>{formik.errors.description}</FormHelperText>
              )}
            </Box>

            <FormControl component="fieldset">
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: AppColors.TextColor 
                }}
              >
                {AppTexts.RecognizablePeople}
              </Typography>
              <RadioGroup
                row
                name="is_recognized"
                value={formik.values.is_recognized}
                onChange={(e) => {
                  formik.setFieldValue("is_recognized", e.target.value);
                  formik.setFieldValue("release_id", []);
                }}
              >
                <FormControlLabel 
                  value="1" 
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  } 
                  label="Yes" 
                />
                <FormControlLabel 
                  value="0" 
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  } 
                  label="No" 
                />
              </RadioGroup>
            </FormControl>

            {formik.values.is_recognized === "1" && (
              <Box sx={{ 
                p: 3, 
                border: `1px solid ${AppColors.BorderColor}`,
                borderRadius: '10px',
              }}>
                {releaseList.length > 0 && (
                  <CustomizedHook
                    dataList={releaseList}
                    setData={multiSearch}
                    selectedList={releaseInfo}
                  />
                )}
                
                {!formik.values.release_id.length && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" gutterBottom>Create a new release</Typography>
                    <AppCustomButton
                      variant={buttonVariants.PRIMARY}
                      size={buttonSizes.MEDIUM}
                      onClick={handleOpen}
                    >
                      Create Release Form
                    </AppCustomButton>
                  </Box>
                )}
              </Box>
            )}

            <FormGroup>
              <Box sx={{ 
                p: 3,
                border: `1px solid ${AppColors.BorderColor}`,
                borderRadius: '10px',
                backgroundColor: '#fafafa'
              }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.is_aigenerated === "1"}
                      onChange={handleAIGeneratedChange}
                      name="is_aigenerated"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  }
                  label={AppTexts.isAIGeneratedText}
                />
              </Box>
            </FormGroup>

            {isAIGenerated && (
              <Box sx={{ 
                p: 3,
                border: `1px solid ${AppColors.BorderColor}`,
                borderRadius: '10px',
                backgroundColor: '#fafafa'
              }}>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {AppTexts.AiNoteTerms}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFictional}
                      onChange={(e) => setIsFictional(e.target.checked)}
                      sx={{
                        color: AppColors.PrimaryColor,
                        '&.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  }
                  label="People and Property are fictional"
                />
                {isFictional && (
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {AppTexts.AiTermsCertify}
                  </Typography>
                )}
              </Box>
            )}

            <FormControl component="fieldset">
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 1,
                  fontWeight: 500,
                  color: AppColors.TextColor 
                }}
              >
                Media Type
              </Typography>
              <RadioGroup
                row
                name="media_type"
                value={formik.values.media_type}
                onChange={(e) => formik.setFieldValue("media_type", e.target.value)}
              >
                <FormControlLabel 
                  value="editorial" 
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  } 
                  label="Editorial" 
                />
                <FormControlLabel 
                  value="creative" 
                  control={
                    <Radio 
                      sx={{
                        '&.Mui-checked': {
                          color: AppColors.PrimaryColor,
                        },
                      }}
                    />
                  } 
                  label="Creative" 
                />
              </RadioGroup>
            </FormControl>

            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              mt: 2,
              width: '50%',
              mx: 'auto'  
            }}>
              <AppCustomButton
                variant={buttonVariants.PRIMARY}
                size={buttonSizes.LARGE}
                onClick={() => formik.setFieldValue("submit_for_approval", 0)}
                type="submit"
              >
                Save Work
              </AppCustomButton>
            </Box>
          </Box>
        </form>
      </Paper>

      <ReleaseFormModal
        open={open}
        onClose={handleClose}
        onRefresh={() => getData(id)}
      />
    </Container>
  );
}

export default GalleryEditDownload;
