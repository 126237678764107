import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Modal,
    IconButton,
    Divider,
    Stack,
    CircularProgress,
    Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { AppColors } from "../constants/AppColors";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDataRedux } from "../redux/slices/cartSlice";
import Storage from "../services/local_storage/localStorage";
import instance from "../services/apiServices/Api";
import AppCustomButton, { buttonSizes, buttonVariants } from "./AppCustomButton";
import { AppTexts } from "../constants/AppTexts";

const CartModal = ({ open, handleClose, isNewItemAdded }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.addToCart.dataOfCart);
    const isLoading = !cartData;
    const token = Storage.getToken();
    const localCart = Storage.getCartData();

    const [deletingItems, setDeletingItems] = useState([]);

    const [showAlert, setShowAlert] = useState(isNewItemAdded);

    useEffect(() => {
        if (isNewItemAdded) {
            setShowAlert(true);
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isNewItemAdded]);

    const subtotal = cartData?.cart_items?.reduce(
        (acc, item) => acc + (item?.download_package?.price || 0),
        0
    );

    const hasItems = cartData?.cart_items?.length > 0;

    const handleDelete = (item, index) => {
        setDeletingItems((prev) => [...prev, item.id]);

        if (!token) {
            localCart.cart_items.splice(index, 1);
            Storage.SetCartData(localCart);
            dispatch(getDataRedux());
            setDeletingItems((prev) => prev.filter((id) => id !== item.id));
        } else {
            instance
                .post(
                    "/cart/remove",
                    { cart_id: item.id },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                )
                .then(() => {
                    dispatch(getDataRedux());
                })
                .catch((error) => {
                    console.error("Error removing item from cart:", error);
                })
                .finally(() => {
                    setDeletingItems((prev) => prev.filter((id) => id !== item.id));
                });
        }
    };

    const navigateToCheckout = () => {

        navigate('/checkout')

    }

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="cart-modal">
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    height: "100vh",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    zIndex: 999,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        bgcolor: "background.paper",
                        zIndex: 1,
                        textAlign: "right",
                        p: 1,
                    }}
                >
                    <IconButton onClick={handleClose} sx={{ color: "gray" }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Header */}
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        bgcolor: "background.paper",
                        zIndex: 1,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        spacing={2}
                    >
                        <Typography variant="body" component="h2">
                            Cart
                        </Typography>
                        {hasItems && (
                            <Typography variant="subtitle1">
                                {cartData.cart_items.length} items
                            </Typography>
                        )}
                    </Stack>
                    <Divider sx={{ backgroundColor: AppColors.BorderColor }} />
                </Box>

                {/* Alert */}
                {showAlert && (
                    <Alert
                        severity="success"
                        onClose={() => setShowAlert(false)}
                        sx={{ m: 2 }}
                    >
                        Added to cart {cartData?.cart_items?.length} items
                    </Alert>
                )}

                {/* Scrollable Cart Items with Shimmer Effect */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        mt: 1,
                        p: 2,
                    }}
                >
                    {isLoading ? (
                        // Shimmer effect while loading
                        Array.from(new Array(3)).map((_, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{ mb: 2 }}
                            >
                                <Box
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        bgcolor: "#e0e0e0",
                                        borderRadius: 1,
                                    }}
                                />
                                <Box sx={{ flexGrow: 1 }}>
                                    <Box
                                        sx={{
                                            width: "70%",
                                            height: 20,
                                            bgcolor: "#e0e0e0",
                                            mb: 1,
                                            borderRadius: 1,
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            width: "50%",
                                            height: 20,
                                            bgcolor: "#e0e0e0",
                                            borderRadius: 1,
                                        }}
                                    />
                                </Box>
                            </Stack>
                        ))
                    ) : hasItems ? (
                        [...cartData.cart_items].reverse().map((item, index) => (
                            <Stack
                                key={index}
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{ mb: 2 }}
                            >
                                <Box
                                    component="img"
                                    src={
                                        item.download.type === "video"
                                            ? item?.download?.watermarked_file?.file
                                            : item?.download?.watermarked_file_small?.file
                                    }
                                    alt={item?.download?.title || "Product Image"}
                                    sx={{ width: 80, height: 80, borderRadius: 1 }}
                                />
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            opacity: 0.8,
                                            transition: 'opacity 0.2s ease-in-out',
                                            '& .MuiTypography-root': {
                                                color: AppColors.PrimaryColor
                                            }
                                        }
                                    }}
                                    component={Link}
                                    to={`/downloads/${item?.download?.slug}`}
                                    onClick={handleClose}
                                >
                                    <Typography variant="body2">
                                        {item?.download?.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color={AppColors.BlackColor}
                                        fontWeight="bold"
                                    >
                                        {item?.download_package
                                            ? `$${item.download_package.price} USD`
                                            : "Free"}
                                    </Typography>
                                </Box>
                                <IconButton
                                    color="error"
                                    onClick={() => handleDelete(item, index)}
                                    sx={{
                                        backgroundColor: "#f0f0f0",
                                        borderRadius: "50",
                                        padding: "4px",
                                    }}
                                    disabled={deletingItems.includes(item.id)}
                                >
                                    {deletingItems.includes(item.id) ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <DeleteIcon />
                                    )}
                                </IconButton>
                            </Stack>
                        ))
                    ) : (
                        <Typography variant="body1" textAlign="center" mt={4}>
                            No items in your cart
                        </Typography>
                    )}
                </Box>

                {/* Footer */}
                {hasItems && (
                    <>
                        <Divider sx={{ backgroundColor: AppColors.BorderColor }} />
                        <Box
                            sx={{
                                position: "sticky",
                                bottom: 0,
                                bgcolor: "background.paper",
                                p: 2,
                            }}
                        >
                            <Typography variant="h6" textAlign="center">
                                Cart Subtotal: ${subtotal?.toFixed(2)} USD
                            </Typography>
                            {/* <Button
                variant="contained"
                fullWidth
                component={Link}
                to="/checkout"
                sx={{
                  mt: 2,
                  backgroundColor: "#ff4081",
                  "&:hover": { backgroundColor: "#e73370" },
                }}
              >
                Checkout
              </Button> */}

                            <Box mt={2}>
                                <AppCustomButton
                                    size={buttonSizes.LARGE}
                                    variant={buttonVariants.PRIMARY}
                                    type="submit"
                                    // disabled={isButtonLoading}
                                    onClick={navigateToCheckout}
                                // to="/checkout"
                                >
                                    {AppTexts.CheckOut}
                                </AppCustomButton>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default CartModal;
